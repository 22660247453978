import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { type IShareLink } from 'Util/Interface'
import dayjs from 'dayjs'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'

const ShareLink: React.FC = () => {
  const [tableData, setTableData] = useState<IShareLink[]>([])

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/shares`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: IShareLink) => {
    try {
      const response = await Http(`/shares/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const columns: ColumnsType<IShareLink> = [
    {
      title: 'Sr. No',
      key: 'index',
      dataIndex: 'index',
      render: (_value, _record, index) => index + 1,
    },
    {
      title: 'Tdl',
      key: 'tdl_image',
      dataIndex: 'tdl_image',
      render: (value, record) => <img src={`${ATTCHMENT_BASE_URL}${record?.tdl?.thumbnail}`} alt="tdlimage" height={70} width={70} />,
    },
    {
      title: 'Tdl Title',
      key: 'title',
      dataIndex: 'title',
      render: (value, record) => <div className="text-capitalize">{record?.tdl?.title}</div>,
    },
    {
      title: 'User',
      key: 'user',
      dataIndex: 'user',
      render: (value, record) => (
        <>
          <div>
            <strong>Name:</strong> <span>{record.fullName}</span>
          </div>
          <div>
            <strong>Phone:</strong> <span>{record.phone}</span>
          </div>
          <div>
            <strong>Email:</strong> <span>{record.email}</span>
          </div>
        </>
      ),
    },
    {
      title: 'Business Email',
      key: 'companyEmail',
      dataIndex: 'companyEmail',
    },
    {
      title: 'Business Phone',
      key: 'businessPhone',
      dataIndex: 'businessPhone',
    },
    {
      title: 'Company Name',
      key: 'companyName',
      dataIndex: 'companyName',
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
    },
    {
      title: 'Date',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => <div>{dayjs(value).format('DD MMM YYYY')}</div>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: IShareLink) => {
        return (
          <Space>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Share Link List</h3>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IShareLink) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
    </>
  )
}

export default ShareLink
