import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { SignIn } from 'Store/services/auth.service'
import { useNavigate } from 'react-router-dom'
import { type ILoginUser } from 'Util/Interface'

const LoginPage: React.FC = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleSubmit = (values: ILoginUser) => {
    setLoading(true)
    dispatch(SignIn(values))
      .unwrap()
      .then(() => {
        setLoading(false)
        navigate('/')
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-bg-wrapper">
          <div className="login-wrapper">
            <div className="form-wrapper">
              <Form layout="vertical" onFinish={handleSubmit} requiredMark="optional" autoComplete="off">
                <div className="form-title">Login App</div>
                <div className="login-description">Enter Authentication Details</div>
                <Form.Item
                  name="email"
                  label={'Email'}
                  rules={[
                    { required: true, message: 'Enter Email Address' },
                    {
                      type: 'email',
                      message: 'Enter Valid Email Address',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder={'Email'} />
                </Form.Item>
                <Form.Item label={'Password'} name="password" rules={[{ required: true, message: 'Enter Password' }]}>
                  <Input.Password className="custom-input" placeholder={'Password'} />
                </Form.Item>
                <Form.Item noStyle>
                  <Button className="custom-button mt-4" type="primary" htmlType="submit" loading={isLoading}>
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
