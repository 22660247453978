import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import RequireAuth from './RequireAuth'
import MainLayout from 'Component/MainLayout/MainLayout'
import LoginPage from 'Pages/LoginPage'
import NotFound from 'Pages/NotFound'
import ProfilePage from 'Pages/ProfilePage'
import MainLoader from 'Component/Common/MainLoader'
import Category from 'Pages/Category'
import Offers from 'Pages/Offers'
import Advertisement from 'Pages/Advertisement'
import TdlDetail from 'Pages/TdlDetail'
import ShareLink from 'Pages/ShareLink'
import Partner from 'Pages/Partner'

const Dashboard = lazy(async () => await import('Pages/Dashboard'))

// no lazy loading for auth pages to avoid flickering
const Logout = React.lazy(async () => await import('./Logout'))

export const DASHBOARD_PATH = '/'

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  return (
    <BrowserRouter>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path={DASHBOARD_PATH} element={protectedLayout}>
            <Route index element={<Dashboard />} />
            <Route path="/category" element={<Category />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/advertisement" element={<Advertisement />} />
            <Route path="/tdl" element={<TdlDetail />} />
            <Route path="/share-link" element={<ShareLink />} />
            <Route path="/partner" element={<Partner />} />
            <Route path="/profile" element={<ProfilePage />} />
          </Route>

          <Route path="login" element={<LoginPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
