import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Form, Input, Modal, Select, Tag, Row, Upload, Image, type UploadProps } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IAddTdlVideo, type ICategory, type ITdlVideo } from 'Util/Interface'
import dayjs from 'dayjs'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import TextArea from 'antd/es/input/TextArea'

const TdlDetail: React.FC = () => {
  const [tableData, setTableData] = useState<ITdlVideo[]>([])
  const [category, setCategory] = useState<ICategory[]>([])
  const [visible, setVisble] = useState(false)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [updateId, setUpdateId] = useState<string>('')
  const [updateUploadImg, setUpdateUploadImg] = useState<any>('')
  const [uploadImg, setUploadImg] = useState<any>('')

  const [form] = Form.useForm()

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/tdl`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  const fetchCategory = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/category`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setCategory(response.data?.data)
      } else {
        setCategory([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setCategory([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
    void fetchCategory()
  }, [fetch, fetchCategory])

  const handleDelete = async (params: ITdlVideo) => {
    try {
      const response = await Http(`/tdl/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (param: IAddTdlVideo) => {
    try {
      const formData = new FormData()
      formData.append('category', String(param?.category))
      formData.append('status', param?.status)
      formData.append('description', param?.description)
      formData.append('title', param?.title)
      formData.append('videoLink', param?.videoLink)

      if (param?.thumbnail) {
        formData.append('thumbnail', param?.thumbnail[0].originFileObj)
      }

      const response = await Http({
        url: !isUpdated ? '/tdl' : `/tdlupdate/${updateId}`,
        method: HTTP_METHOD.POST,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        if (!isUpdated) {
          form.resetFields()
        }
        setVisble(false)
        setIsUpdated(false)
        setUpdateId('')
        setUpdateUploadImg('')
        void fetch()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpdate = (param: ITdlVideo) => {
    setIsUpdated(true)
    setUpdateId(param.id)
    form.setFieldsValue({
      title: param?.title,
      status: param?.status,
      description: param.description,
      videoLink: param.videoLink,
      category: param.categoryId,
    })
    setUpdateUploadImg(param.thumbnail)
    setVisble(true)
  }

  const handleCancel = () => {
    setVisble(false)
    setIsUpdated(false)
    setUpdateId('')
    setUpdateUploadImg('')
    form.resetFields()
  }

  const columns: ColumnsType<ITdlVideo> = [
    {
      title: 'Sr. No',
      key: 'index',
      dataIndex: 'index',
      render: (_value, _record, index) => index + 1,
    },
    {
      title: 'Tdl',
      key: 'thumbnail',
      dataIndex: 'thumbnail',
      render: (value) => <img src={`${ATTCHMENT_BASE_URL}${String(value)}`} alt="tdlimage" height={70} width={70} />,
    },
    {
      title: 'Tdl Title',
      key: 'title',
      dataIndex: 'title',
      render: (value) => <div className="text-capitalize">{value}</div>,
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (value) => <div style={{ wordBreak: 'break-all' }}>{value?.length <= 50 ? value : `${String(value?.slice(0, 60))}...`}</div>,
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'category',
      render: (value, record) => <div className="text-capitalize">{record?.category?.title}</div>,
    },
    {
      title: 'Date',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => <div>{dayjs(value).format('DD MMM YYYY')}</div>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: ITdlVideo) => {
        return (
          <Space>
            <Button
              onClick={() => {
                handleUpdate(record)
              }}
            >
              Update
            </Button>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const props: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadImg(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Tdl List</h3>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setVisble(true)
                  }}
                >
                  Add Tdl
                </Button>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: ITdlVideo) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      <Modal
        title={!isUpdated ? 'Add TDL Detail' : 'Update TDL Detail'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Space key={'action'}>
            <Button type="primary" onClick={form.submit} className="btn-padding">
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space>,
        ]}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          onFinish={(param: ITdlVideo) => {
            void handleUpsert(param)
          }}
          requiredMark="optional"
        >
          <Form.Item
            label="Category"
            name="category"
            rules={[
              {
                required: true,
                message: 'Select Category!',
              },
            ]}
          >
            <Select placeholder="Select Category" className="custom-select">
              {category?.map((ele: ICategory, idx) => {
                return (
                  <Select.Option value={ele?.id} key={idx}>
                    {ele?.title}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="TDL Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please Enter TDL Title!',
                type: 'string',
              },
            ]}
            className="custom-input-label mb-6"
          >
            <Input className="custom-input" placeholder="Enter TDL Title" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please Enter Description!',
                type: 'string',
              },
            ]}
            className="custom-input-label mb-6"
          >
            <TextArea className="custom-input" placeholder="Enter Description" />
          </Form.Item>

          <Form.Item
            label="Vimeo Link"
            name="videoLink"
            rules={[
              {
                required: true,
                message: 'Please Enter Vimeo Link!',
                type: 'string',
              },
            ]}
            className="custom-input-label mb-6"
          >
            <Input className="custom-input" placeholder="Enter Vimeo Link" />
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              className="custom-select"
              options={[
                { value: 'Active', label: 'Active' },
                { value: 'InActive', label: 'InActive' },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="TDL Thumbnail"
            name="thumbnail"
            rules={[
              {
                required: !isUpdated,
                message: 'Please Upload TDL Thumbnail!',
              },
            ]}
            valuePropName="fileList"
            getValueFromEvent={(event) => {
              return event?.fileList
            }}
          >
            <Upload {...props}>{uploadImg?.name ? <Button>{String(uploadImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
          </Form.Item>
          <Row justify={'center'} className="mb-5">
            {updateUploadImg && <Image width={100} src={`${ATTCHMENT_BASE_URL}${String(updateUploadImg)}`} />}
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default TdlDetail
