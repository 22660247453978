import React from 'react'
import { AliwangwangOutlined, CodepenOutlined, CreditCardOutlined, DribbbleSquareOutlined, FundOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    url: '/',
    icon: <HomeOutlined />,
  },
  {
    title: 'Category',
    key: 'category',
    url: '/category',
    icon: <CodepenOutlined />,
  },
  {
    title: 'Offers',
    key: 'offers',
    url: '/offers ',
    icon: <CreditCardOutlined />,
  },
  {
    title: 'Advertisement',
    key: 'advertisement',
    url: '/advertisement ',
    icon: <FundOutlined />,
  },
  {
    title: 'TDL Video',
    key: 'tdl',
    url: '/tdl',
    icon: <DribbbleSquareOutlined />,
  },
  {
    title: 'All Share Link',
    key: 'share-link',
    url: '/share-link',
    icon: <AliwangwangOutlined />,
  },
  {
    title: 'Partners',
    key: 'partner',
    url: '/partner',
    icon: <UserOutlined />,
  },
]
