import { Card, Col, Row } from 'antd'
import React from 'react'

const ProfilePage: React.FC = () => {
  return (
    <>
      <Row gutter={[30, 30]}>
        <Col xs={24} md={24} xl={8}>
          <Card className="common-card-wrapper">
            <Row gutter={[30, 30]}>
              <Col xs={24} md={12} xl={24}></Col>

              <Col xs={24} md={12} xl={24}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ProfilePage
