export interface ILoginUser {
  email: string
  password: string
}

export interface IPagination {
  limit?: number
  skip?: number
  total?: number
}

export interface ICategory {
  id: string
  title: string
  status?: string
  created_at: string
  updated_at: string
}

export interface ICategoryForm {
  id: string
  status: string
  title: string
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface IOffer {
  id: string
  title: string
  description: string
  price: number
  gst: number
  status: string
  created_at: string
  updated_at: string
}

export interface IShareLink {
  id: string
  fullName: string
  companyName: string
  email: string
  companyEmail: string
  phone: string
  businessPhone: string
  address: string
  shareUrl: string
  tdlId: string
  userId: string
  status: string
  created_at: string
  updated_at: string
  user: IShareUser
  tdl: IShareTdl
}

export interface IShareUser {
  id: string
  name: string
  companyName: string
  email: string
  companyEmail: string
  phone: string
  businessPhone: string
  address: string
  profileImage: any
}

export interface IShareTdl {
  id: string
  title: string
  thumbnail: string
  videoLink: string
  created_at: string
}

export interface IPartner {
  id: string
  name: string
  companyName: string
  email: string
  companyEmail: string
  phone: string
  businessPhone: string
  address: string
  profileImage: any
  status: string
  created_at: string
  updated_at: string
}

export interface IAdvertisement {
  id: string
  banner: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddAdvertisement {
  id: string
  thumbnail: any
  status: string
}

export interface ITdlVideo {
  id: string
  title: string
  description: string
  thumbnail: string
  videoLink: string
  categoryId: string
  status: string
  created_at: string
  updated_at: string
  category: ICategory
}

export interface IAddTdlVideo {
  id: string
  title: string
  description: string
  thumbnail: any
  videoLink: string
  categoryId: string
  status: string
  created_at: string
  updated_at: string
  category: ICategory
}

export interface IDashboard {
  category: number
  partner: number
  tdl: number
  offer: number
  advertisement: number
  sharelink: number
}
